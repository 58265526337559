import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid';
import postFetcher, { postParamRequest } from 'api/helpers/postFetcher';
import { getBookingLogs, postsaveInternalRemarks } from 'api/helpers/api-constants';
import { useReduxReducer } from 'redux/store';
import { useTranslation } from 'react-i18next';

export interface AgencyData {
  bookingKey: string;
  email: string;
  phone: string;
  agencyNumber: string;
  providers: string;
  internalRemarks: string | null;
  name: string;
}

interface LogItem {
  id: number;
  date: string;
  user_id: number;
  username: string;
  agencyId: number;
  agencyNumber: string;
  agencyName: string;
  ip: string;
  logType: string;
  requestUrl: string;
  requestParameters: string;
  bookingKey: string;
  details: string;
  channel: string;
  correlationId: string;
}


interface SectionAgencyProps {
  data: AgencyData;
}

export const AgencySection: React.FC<SectionAgencyProps> = ({ data }) => {
  const {t} = useTranslation()
  const [showAtrOnlyArea, setShowAtrOnlyArea] = useState(false);
  const [internalRemarks, setInternalRemarks] = useState<string | null>(data.internalRemarks);
  const {isAdmin} = useReduxReducer(state => state.general)
  const handleHeaderClick = () => setShowAtrOnlyArea(!showAtrOnlyArea);
  const getPaymentType = (requestParameters: string): string => {
    const match = requestParameters.match(/paymentType=\[([^\]]+)\]/);
    return match ? `[${match[1]}]` : ''; // Return the text inside brackets, keeping the brackets
  };
  function trimText(text: string, maxLength: number): string {
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  }
  const getListItemHtml = (item: LogItem) => {
    return `
      <li class="">
        <div class=" py-2  gap-1 grid grid-cols-4 items-center text-base  text-gray-900 hover:bg-gray-200 hover:cursor-pointer">
          <span>${item.date} <p>-</p> <p>${item.correlationId}</p> </span>
          <span>${item.username}<p>${item.ip}</p></span>
          <span class="whiteSpace-nowrap">${item.logType} <p class="text-primary-6000">${getPaymentType(item.requestParameters)}</p> </span>
          <span>${trimText(item.details, 300)}</span>

        </div>
      </li>
    `;
  };

  const showList = async () => {
    const payload = { bookingKey: data.bookingKey };
    const response = await postParamRequest(getBookingLogs(), payload);
    if (response) {
      const list: LogItem[] = response;

      const listHtml = `
        <div class="font-semibold grid grid-cols-4 border-b p-2">
          <span>Tarih / CorrID</span>
          <span>Kullanici / IP</span>
          <span>Islem</span>
          <span>Detay</span>
        </div>
        <ul class="list divide-y  divide-gray-200 overflow-y-scroll">
          ${list.map(getListItemHtml).join('')}
        </ul>
      `;

      await Swal.fire({
        title: 'History',
        html: listHtml,
        width: 1200,
        showCloseButton: true,
      });
    }
  };

  const saveInternalRemarks = async (bookingKey: string, internalRemarks: string) => {
    const payload = {
      bookingKey: bookingKey,
      remarks: internalRemarks,
    };
    try {
      await postFetcher(postsaveInternalRemarks(), payload);
      Swal.fire('Success', '', 'success');
    } catch (error) {
      Swal.fire('Error', '', 'error');
    }
  };

  return (
    <div>
      {isAdmin && (
        <div className="border-gray mt-2 mb-4 w-full rounded bg-white p-2 shadow-sm">
          <h4 className="cursor-pointer font-semibold" onClick={handleHeaderClick}>
            <span className="flex justify-start">
              ATR Only
              {showAtrOnlyArea ? (
                <ChevronUpIcon className="ml-auto h-6 w-6" />
              ) : (
                <ChevronDownIcon className="ml-auto h-6 w-6" />
              )}
            </span>
          </h4>
          <div className={`transition ${showAtrOnlyArea ? 'ease-out duration-500 transform opacity-100 scale-100' : 'ease-in duration-500 transform opacity-0 scale-95'}`}>
            {showAtrOnlyArea && (
              <div className="bg-gray-200">
                <div className="w-full p-6">
                  <div className="flex h-40 min-w-full">
                    <textarea
                      id="internalRemarks"
                      className="form-control flex-grow p-2"
                      placeholder="Gizli Notlar"
                      onChange={(e) => setInternalRemarks(e.target.value)}
                      value={internalRemarks ? internalRemarks : ""}
                    />
                    <div className="flex justify-end">
                      <button
                        id="saveInternalRemarksBtn"
                        className="border-1 ml-3 h-10 rounded border-black bg-gray-200 px-4 py-2 text-black hover:bg-gray-400"
                        type="button"
                        onClick={() => saveInternalRemarks(data.bookingKey, internalRemarks?? "")}
                      >
                        Kaydet
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex justify-start py-6">
                  <button
                    className="border-1 ml-3 rounded border-black bg-gray-200 px-4 py-2 text-black hover:bg-gray-400"
                    id="getBookingLogsBtn"
                    onClick={showList}
                  >
                    History Sorgulama
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="mb-4 items-center grid grid-rows-2 rounded bg-white mx-4">
        <div className="flex justify-between">
        {isAdmin && <div>
                <span className='text-gray-900 font-semibold'>Tedarikçi Sistem:  </span>
                <span className=' text-red-500 text-lg'>{data.providers}</span>
               
                </div>}
          <h2 className="text-lg font-bold">
           
          </h2>
          <button
            title="Copy"
            className="rounded border bg-gray-100 px-3 shadow-sm h-8"
            onClick={() => navigator.clipboard.writeText(data.bookingKey)}
          >
            <i className="fa fa-copy" aria-hidden="true"></i>
            <span className="text-base ml-2 font-semibold">
              {data.bookingKey}
            </span>
          </button>
        </div>
        <div className="text-left grid grid-rows-2 items-end justify-end text-md mt-2">
           
          <p className="text-gray-700">
            <b className="pr-2">{t("manage-agencies.email")}: </b>
            {data.email}
          </p>
          <p className="pr-2 text-gray-700">
            <b>{t("generals.phone")}: </b> {data.phone}
          </p>
          <p className="pr-2 text-gray-700">
            <b>{t("manage-agencies.agency")}: </b> {data.name}
          </p>
          <p className="pr-2 text-gray-700">
            <b>{t("manage-agencies.agency-number")}: </b> {data.agencyNumber}
          </p>
        </div>
      </div>
    </div>
  );
};

